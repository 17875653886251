import './Strip.css';
import star from './resources/star.png'
export default function Strip(){
    return(
        <div className='strip'>
            <div className='inner-strip'>
                <div className='inner-strip-text'>Code    </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Develop </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Deploy  </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Secure  </div>
                <img className='inner-strip-img' src={star} alt=''/>

                <div className='inner-strip-text'>Code    </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Develop </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Deploy  </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Secure  </div>
                <img className='inner-strip-img' src={star} alt=''/>


                <div className='inner-strip-text'>Code    </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Develop </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Deploy  </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Secure  </div>
                <img className='inner-strip-img' src={star} alt=''/>


                <div className='inner-strip-text'>Code    </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Develop </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Deploy  </div>
                <img className='inner-strip-img' src={star} alt=''/>
                <div className='inner-strip-text'>Secure  </div>
                <img className='inner-strip-img' src={star} alt=''/>
            </div> 
        </div>
    );
}
