import './ContactForm.css';
import React, { useState } from 'react';
import { sendConnectDatatoBackend } from '../services/data';

const ContactForm = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      email,
      phone,
      reason,
      message,
      otherReason,
    };
    const fetchConnectResult = async () => {
      try {
        const response = await sendConnectDatatoBackend(formData);
        if (response && response.message) {
          console.log(response.message);
          // Reset form fields
          setEmail('');
          setPhone('');
          setReason('');
          setMessage('');
          setOtherReason('');
          // Close the popup
          onClose();
        } else {
          console.error('Invalid response from the server:', response);
        }
      } catch (error) {
        console.error('Error sending contact form data:', error);
      }
    };
  
    fetchConnectResult();
    
    // Reset form fields
    setEmail('');
    setPhone('');
    setReason('');
    setMessage('');
    setOtherReason('');
    // Close the popup
    onClose();
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Contact Form</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
        </div>

        <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            />
        </div>

        <div className="form-group">
            <label htmlFor="reason">Reason:</label>
            <select
            id="reason"
            className="reason-select"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
            >
            <option value="">Select a reason</option>
            <option value="Software/ Web Development">Software/ Web Development</option>
            <option value="IoT and Automation">IoT and Automation</option>
            <option value="Drone">Drone</option>
            <option value="Workshop/ Conference">Workshop/ Conference</option>
            <option value="other">Other</option>
            </select>
            {reason === "other" && (
            <input
                type="text"
                className="other-reason-select"
                id="otherReason"
                placeholder="Please specify the reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                required
            />
            )}
        </div>
        <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            required
            ></textarea>
        </div>

        <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
