import './QuoteSection.css';
import arrow from './resources/arrow.png'

export default function QuoteSection(){

    const handleButtonClick = () => {
        console.log("Button clicked"); // Example log statement, replace with desired functionality
      };

    return(
        <div>
            <div className="quote-section">
                <div className="quote-section-text">See the impact of creative and innovative people on your business. </div>
                <button className="hire-me-button hire-me-button-hover" onClick={handleButtonClick}>Hire Me <img src={arrow}/></button>
            </div>
        </div>
    );
}