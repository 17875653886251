import './Projects.css'
import { useEffect, useRef, useState } from 'react';
import defaultProjectImg from './resources/defaultProjectImg.jpg'
import { getProjects } from '../services/data';

export default function Projects() {
    const projectsRef = useRef(null);
    const [inViewport, setInViewport] = useState(false);
    const [projects, setProjects] = useState([]);
  
    useEffect(() => {
        const fetchProjects = async () => {
          try {
            const result = await getProjects();
            setProjects(result);
          } catch (error) {
            console.log('Error fetching projects:', error);
          }
        };
        fetchProjects();
      }, []);
  
    useEffect(() => {
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInViewport(true);
          } else {
            setInViewport(false);
          }
        });
      };
  
        const observer = new IntersectionObserver(handleIntersection);
        observer.observe(projectsRef.current);
  
        return () => {
          observer.disconnect();
        };

    }, []);
    return (
      <div id='projects'>
        <div className='latest-projects-text'>Latest Projects</div>
        <div
          className={`projects-section ${inViewport ? 'animation' : ''}`}
          ref={projectsRef}
        >
          <div className='project-list'>
            {projects.map((project, index) => (
              <div className='project-card' key={index}>
                <img
                  src={project.image ? project.image : defaultProjectImg}
                  alt={project.title}
                />
                <h3>{project.title}</h3>
                <p>{project.techStacks}</p>
                <div className='project-description'>{project.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}
  