import axios from "axios";

const backendUrl="https://backend.kshitijkrsharma.com/";
// const backendUrl="http://localhost:3001/";

export const getData = async () => {
    try{
    let response = await axios.get(backendUrl+"data");
    return response.data;
    }catch(error){
        if(error.response && error.response.status ===404){
            console.log("Resource not found");
        }
        else{
            console.log("Something went wrong "+ error.message);
        }
        return []
    }
}
export const getAbout = async () => {
    try{
    let response = await axios.post(backendUrl+"about");
    return response.data;
    }catch(error){
        if(error.response && error.response.status ===404){
            console.log("Resource not found");
        }
        else{
            console.log("Something went wrong "+ error.message);
        }
        return []
    }
}
export const getProjects = async () =>{
    try{
        let response = await axios.post(backendUrl+"projects");
        return response.data;
    }catch(error){
        if(error.response && error.response.status ===404){
            console.log("Resource not found");
        }
        else{
            console.log("Something went wrong "+ error.message);
        }
        return []
    }
}
export const getWorkedWith = async () =>{
    try{
        let response = await axios.post(backendUrl+"worked_with");
        return response.data;
    }catch(error){
        if(error.response && error.response.status ===404){
            console.log("Resource not found");
        }
        else{
            console.log("Something went wrong "+ error.message);
        }
        return []
    }
}
export const getSocialMedia = async () =>{
    try{
        let response = await axios.post(backendUrl+"social_media");
        return response.data;
    }catch(error){
        if(error.response && error.response.status ===404){
            console.log("Resource not found");
        }
        else{
            console.log("Something went wrong "+ error.message);
        }
        return []
    }
}
export const sendConnectDatatoBackend = async (formData) => {
    try {
      const response = await axios.post(backendUrl+'contact', formData); 
      return response.data;
    } catch (error) {
      console.error('Error sending contact form data:', error);
    }
  };
  