import React, { useState, useEffect } from "react";
import "./HomeSlider.css";
import HomeSliderContent1 from "./HomeSliderContent1";
import HomeSliderContent2 from "./HomeSliderContent2";
import ContactForm from "./ContactForm";

function HomeSlider() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleClickContact = () => {
    setIsFormOpen(true);
  };

  const handleCloseContact = () => {
    setIsFormOpen(false);
  };

  const slides = [
    {
      id: 1,
      component: <HomeSliderContent1 onClickContact={handleClickContact}/>,
    },
    {
      id: 2,
      component: <HomeSliderContent2 onClickContact={handleClickContact}/>,
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setActiveSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [isHovered, slides.length]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div id='slider'>
      <div className="slider-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >

        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${
              activeSlide === index
                ? "active"
                : activeSlide < index
                ? "next"
                : "previous"
            }`}
          >
            {slide.component}
          </div>
        ))}
      </div>
      {isFormOpen && <ContactForm onClose={handleCloseContact} />}
    </div>
  );
}
export default HomeSlider;
