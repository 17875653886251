import React from "react";
import './HomeSliderContent2.css'
import arrow from './resources/arrow.png'
import droneflow from './resources/droneflow.png'

function HomeSliderContent2({ onClickContact}) {
  return (
    <div>
      <div className="slider-row">
        <div className="slider-column">
          <div className="quote">Helping students, companies, and hobbyists in developing IoT gadgets and drones.</div>
          <div className="text">I have been developing IoT gadgets and drones for over 3 years</div>
          <button className="slider2-button slider2-button-hover" onClick={onClickContact}>Contact Me <img src={arrow}/></button>
        </div>
        <div className="slider-column">
          <div className="image-container">
            <div className="image-wrapper">
              <img src={droneflow} alt="Kshitij"  className="responsive-image"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSliderContent2;
