import React from "react";
import './HomeSliderContent1.css'
import arrow from './resources/arrow.png'
import kshitij from './resources/kshitij.png'

function HomeSliderContent1({ onClickContact}) {
  return (
      <div className="slider-row">
        <div className="slider-column">
          <div className="quote">Coding, Developing, Deploying and Securing Solutions for Amazing People</div>
          <div className="text">I have been coding and developing software solutions as a software engineer for over 1 year</div>
          <button className="slider-button slider-button-hover" onClick={onClickContact}>Contact Me <img src={arrow}/></button>
        </div>
        <div className="slider-column">
          <div className="image-container">
            <div className="image-wrapper">
              <img src={kshitij} alt="Kshitij"  className="responsive-image"/>
            </div>
          </div>
        </div>
      </div>
  );
}

export default HomeSliderContent1;
