import HomeSlider from './HomeSlider';
import Strip from './Strip';
import About from './About';
import Projects from './Projects';
import WorkedWith from './WorkedWith';
import QuoteSection from './QuoteSection';

function Content(){
    return(
        <>
            <HomeSlider />
            <Strip />
            <About/>
            <Projects/>
            <WorkedWith/>
            <QuoteSection/>
        </>
    );
}
export default Content;