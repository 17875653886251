import React from "react";
import {useState} from 'react';
import arrow from './resources/arrow.png'
import './Navbar.css'
import ContactForm from "./ContactForm";

export default function Navbar(){
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);

    const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
    };
    const closeForm = () => {
        setIsFormOpen(false);
      };

    const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    };
    return(
        <nav className="navbar">
          <div className="navbar-brand"><a href="./">KKS</a></div>
          <button className="nav-toggle" onClick={toggleNav}>
          </button>
          <ul className={`navbar-menu ${isNavOpen ? 'show' : ''}`}>
          <li><a href="./">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#worked-with">Works</a></li>
          <li><a href="#projects">Projects</a></li>
          <li>
              <a className="contact-me" onClick={toggleForm}>
              Contact
              <img src={arrow} alt='-->'/>
              </a>
          </li>
          {isFormOpen && <ContactForm onClose={closeForm}/>}
          </ul>
      </nav>
    );
}