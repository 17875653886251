import './WorkedWith.css';
import { useState, useEffect, useRef } from 'react';
import { getWorkedWith } from '../services/data';

export default function WorkedWith() {
  const [workedWith, setWorkedWith] = useState([]);
  const workedWithRef = useRef(null);
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const fetchWorkedWith = async () => {
      try {
        const result = await getWorkedWith();
        setWorkedWith(result);
      } catch (error) {
        console.log('Error fetching projects:', error);
      }
    };
    fetchWorkedWith();
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInViewport(true);
        } else {
          setInViewport(false);
        }
      });
    };

      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(workedWithRef.current);

      return () => {
        observer.disconnect();
      };

  }, []);


  return (
    <div id='worked-with'>
      <div className="worked-with-section" >
        <div className="worked-with-text">I've Worked With</div>
        <div 
        className={`worked-with-logos ${inViewport ? 'animation' : ''}`}
        ref={workedWithRef}
        >
          <div className='logo-list'>
          {workedWith.map((item, index) => (
            <div className="logo-container" key={index}>
              <img className="company-logo" src={item.logo} alt={item.name} />
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
}
