import { useState, useEffect } from 'react';
import { getAbout } from '../services/data'
import './About.css'
export default function About(){
    const [about, setAbout] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [isHoveredLn, setIsHoveredLn] = useState(false);
    const [isHoveredT, setIsHoveredT] = useState(false);
    const [isHoveredGh, setIsHoveredGh] = useState(false);

    const handleIconHoverLn = () => {
        setIsHoveredLn(!isHoveredLn);
    };
    const handleIconHoverT = () => {
        setIsHoveredT(!isHoveredT);
    };
    const handleIconHoverGh = () => {
        setIsHoveredGh(!isHoveredGh);
    };

    useEffect(() => {
        const fetchAbout = async () => {
          const result = await getAbout();
          setAbout(result);
        };
        fetchAbout();
      }, [about.length]);
    
    if (about.length === 0) {
        return <div className='about-container'>Loading...</div>; 
        }
    else{
        const isSmallScreen = window.innerWidth <= 600;
        const visibleParagraphs = isSmallScreen && !expanded ? about.paragraph.slice(0, 2) : about.paragraph;    
        const handleExpandToggle = () => {
            setExpanded(!expanded);
          };
      
        return(
        <div id='about'>
            <div className="about-container">
                <h1 className="about-heading">{about.heading}</h1>
                <div className="about-paragraphs">
                    {visibleParagraphs.map((text, index) => (
                        <p key={index}>{text}</p>
                    ))}
                    {isSmallScreen && (
                        <div className="expand-button" onClick={handleExpandToggle}>
                        <span>{expanded ? 'Show Less' : 'Expand'}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 4L1 15h22L12 4zm0 2.83L19.17 13H4.83L12 6.83z" />
                        </svg>
                        </div>
                    )}
                </div>
                <button className='redirect-edu-cert'> Education and Certifications</button>
            </div>
            <div className='below-about-strip'>
                <div className='left-design'>
                <div className="shape-left"></div>
                </div>
                <div className='middle-follow-social-media'>
                    <div>follow me on : </div>
                    <a href={about.socialMedia.linkedin} target="_blank" rel="noopener noreferrer">
                        <i
                            className={`fa-brands fa-linkedin-in ${isHoveredLn ? 'fa-2xl' : 'fa-xl fa-beat-fade'}`}
                            onMouseEnter={handleIconHoverLn}
                            onMouseLeave={handleIconHoverLn}
                            style={{color:'black'}}
                        ></i>
                    </a>
                    <a href={about.socialMedia.twitter} target="_blank" rel="noopener noreferrer">
                        <i
                            className={`fa-brands fa-twitter fa-xl ${isHoveredT ? 'fa-2xl' : 'fa-xl fa-beat-fade'}`}
                            onMouseEnter={handleIconHoverT}
                            onMouseLeave={handleIconHoverT}
                            style={{color:'black'}}
                        ></i>
                    </a>
                    <a href={about.socialMedia.github} target="_blank" rel="noopener noreferrer">
                        <i
                            className={`fa-brands fa-github fa-xl ${isHoveredGh ? 'fa-2xl' : 'fa-xl fa-beat-fade'}`}
                            onMouseEnter={handleIconHoverGh}
                            onMouseLeave={handleIconHoverGh}
                            style={{color:'black'}}
                        ></i>
                    </a>
                </div>
                <div className='right-design'>
                    <div className='shape-right'></div>
                </div>
            </div>
        </div>
        );
    }
}