import React, { useState, useEffect } from "react";
import arrow from './resources/arrow.png'
import './Footer.css'
import { getSocialMedia } from "../services/data";
export default function Footer(){
    const [socialMedia, setSocialMedia] = useState([]);
    useEffect(() => {
        const fetchSocialMedia = async () => {
          try {
            const result = await getSocialMedia();
            setSocialMedia(result);
          } catch (error) {
            console.log('Error fetching projects:', error);
          }
        };
        fetchSocialMedia();
      }, []);
    const submitEmailForNewsletter = ()=>{
        console.log("Email for Newsletter");
    }
    return(
        <footer>
        <div className="row">
            <div className="footer-parent-section">
                <div className="row">
                    <div className="footer-inner-section">
                    <h4>Navigations</h4>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li><a href="#about">About </a></li>
                        <li><a href="#worked-with">Works</a></li>
                        <li><a href="#slider">Contact</a></li>
                    </ul>
                    </div>
                    <div className="footer-inner-section">
                    <h4>Services</h4>
                    <ul>
                        <li><a href="./">Software </a></li>
                        <li><a href="./">Drone</a></li>
                        <li><a href="./">IoT</a></li>
                        <li><a href="./">Automation</a></li>
                    </ul>
                    </div>
                    <div className="footer-inner-section">
                    <h4>Social Media</h4>
                    <ul>
                        <li><a href={socialMedia.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                        <li><a href={socialMedia.twitter} target="_blank" rel="noopener noreferrer">Twitter</a></li>
                        <li><a href={socialMedia.github} target="_blank" rel="noopener noreferrer">Github</a></li>
                        <li><a href="./">Instagram</a></li>
                    </ul>
                    </div>
                    <div className="footer-inner-section-below">
                    <h4>Information</h4>
                    <ul>
                        <li><a href="./">Privacy Policy</a></li>
                        <li><a href="./">Terms of Use</a></li>
                        <li><a href="./">FAQs</a></li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className="footer-parent-section">
                <div className="footer-right-inner-section">
                <h4>Be Innovative,</h4>
                <h3>Be creative,</h3>
                <h2>Be Solutive</h2>
                <div className='newsletter'>
                    <input type="email" placeholder="Enter your email address"/>
                    <input type="image" src={arrow} alt="Submit" onClick={submitEmailForNewsletter}/>
                </div>
                </div>
            </div>
      </div>
      <p>&copy; 2023 KKS. All rights reserved.</p>
      </footer> 
    );
}